import React, {useState} from "react";
import {useTranslation, Trans} from 'gatsby-plugin-react-i18next';
import Layout from "../../../../components/layout";
import Seo from "../../../../components/seo";
import {graphql} from 'gatsby';
import RevealerWrapper from '../../../../components/Revealer/RevealerWrapper';
import VideoIcons from '../../../../components/video-player/VideoIcons';
import ReactVideo360 from "../../../../components/video-player/ReactVideo360";
import {createMarkup} from '../../../../utils/utils';
import compareLeft from './images/combo.png';
import compareRight from './images/rfid.png';
import {StaticImage} from "gatsby-plugin-image";
import posterKeyOval from '../../../../images/support/posters/360s/range/NLRGOVDLBN_SP_360ROT2.jpeg';
import posterRfidOval from '../../../../images/support/posters/360s/range/NLRGOVRFBN_SP_360ROT2.jpeg';
import posterKeySquare from '../../../../images/support/posters/360s/range/NLRGSTDLBN_SP_360ROT2.jpeg';
import posterRfidSquare from '../../../../images/support/posters/360s/range/NLRGSTRFBN_SP_360ROT2.jpeg';
import posterKeyVertical from '../../../../images/support/posters/360s/range/NLRGNWDLBN_SP_360ROT2.jpeg';
import posterRfidVertical from '../../../../images/support/posters/360s/range/NLRGNWRFBN_SP_360ROT2.jpeg';

const Axis = (props) => {

  const {t} = useTranslation();
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [bcVideoId, setBcVideoId] = useState('https://cdn.mediavalet.com/usva/digilock/fYu5_xqhi0aP3RtCNvduYA/vePXyIIC50GRohJqdHtepg/Original/NLRGOVDLBN_SP_360ROT2.mp4');
  const [currentPoster, setCurrentPoster] = useState(posterKeyOval);


  function videoIconClickHandler(index, vid, poster) {
    setCurrentVideoIndex(index);
    setBcVideoId(vid);
    setCurrentPoster(poster)
  }

  return (
      <Layout>
        <Seo
            title={t('products_electronic_locks_range')}
            description={t('des_products_electronic_locks_range')}
        />
        <div className={'products electronic-locks range'}>
          <section className="details">
            <div className="container">
              <StaticImage
                  src="../../../../images/logos/logo-range.png"
                  loading={'lazy'}
                  width={252}
                  height={58}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Range logo"
              />
              <p dangerouslySetInnerHTML={{__html:t('range_legacy_support_link')}} />
              <br/><br/>
              <h1>{t('product_details')}</h1>
              <div className={'grid-two-col'}>
                <div className="grid-two-col-item">
                  <div className="revealer center-top">
                    <RevealerWrapper
                        compareLeft={compareRight}
                        compareRight={compareLeft}
                        width={200}
                        labelLeft={createMarkup(t('touch'))}
                        labelRight={createMarkup(t('keypad'))}
                        clsName={'nextlock'}
                    />
                  </div>
                  <div className="product-copy">
                    <h2>
                      {t('interface')}
                    </h2>
                    <h3>
                      {t('front_unit')}
                    </h3>
                    <h3>
                      {t('keypad')} : {t('rfid')} : {t('combo')}
                    </h3>
                    <p className={'pad-top'}>
                      {t('range_interface_copy')}
                    </p>
                    <h3>
                      {t('power')} | 4 AA {t('batteries')}
                    </h3>
                    <p className={'pad-top'}>
                      {t('interface_power_copy_2')}*
                    </p>
                    <p className={'footnote'}>
                      <em>
                        *{t('based_on_average_usage')}
                      </em>
                    </p>
                  </div>
                </div>
                <div className="grid-two-col-item">
                  <div className="video-360">
                    <ReactVideo360
                        poster={currentPoster}
                        vURL={bcVideoId} />
                  </div>
                  <div className="product-copy">
                    <h2>
                      {t('body_style')}
                    </h2>
                    <h3>
                      {t('front_unit')}
                    </h3>
                    <h3>
                      {t('finish')} | {t('brushed_nickel')}
                    </h3>
                    <p>
                      {t('range_body_finish_copy')}
                    </p>
                    <h3>
                      {t('body')} | {t('standard')} : {t('vertical')} : {t('horizontal')}
                    </h3>
                    <VideoIcons
                        currentVideoIndex={currentVideoIndex}
                        videoIconClickHandler={videoIconClickHandler}
                        toggleClassNames={
                          [
                            'range_ovl_key',
                            'range_ovl_rfid',
                            'range_std_key',
                            'range_std_rfid',
                            'range_vrt_key',
                            'range_vrt_rfid'
                          ]
                        }
                        bcVideoIds={
                          [
                            'https://cdn.mediavalet.com/usva/digilock/fYu5_xqhi0aP3RtCNvduYA/vePXyIIC50GRohJqdHtepg/Original/NLRGOVDLBN_SP_360ROT2.mp4',
                            'https://cdn.mediavalet.com/usva/digilock/XUMtzj-6j0SlupNaq7scSw/WQZzxQKWV0CTW-ISucuPUw/Original/NLRGOVRFBN_SP_360ROT2.mp4',
                            'https://cdn.mediavalet.com/usva/digilock/aDeKs2HneU65N3sg4vBC3g/bA5HNwbkE0O2iA5v1HVVag/Original/NLRGSTDLBN_SP_360ROT2.mp4',
                            'https://cdn.mediavalet.com/usva/digilock/orR7X6Y2u0y1hMTrB4b4Dg/tTDKg82lG0uN_Bq_GD4Ucw/Original/NLRGSTRFBN_SP_360ROT2.mp4',
                            'https://cdn.mediavalet.com/usva/digilock/DOkaBZJsRk-wcwPC7Ou2OA/xES1HroEd0S9zHzKGdVbtA/Original/NLRGNWDLBN_SP_360ROT2.mp4',
                            'https://cdn.mediavalet.com/usva/digilock/qOAe-MO1M0qJH8PqNzulyw/JLAC4eKx506zQrZ1_Ch-og/Original/NLRGNWRFBN_SP_360ROT2.mp4'
                          ]
                        }
                        posters={
                          [
                            posterKeyOval,
                            posterRfidOval,
                            posterKeySquare,
                            posterRfidSquare,
                            posterKeyVertical,
                            posterRfidVertical
                          ]
                        }
                    >&nbsp;</VideoIcons>
                  </div>
                </div>

              </div>
            </div>
          </section>
          <div className="section-delimeter container" />
          <section className="rear-units">
            <div className="container">
              <h1>{t('locking_options')}</h1>

              <div className="grid-two-col">
                <div className="grid-two-col-item">
                  <div>
                    <StaticImage
                        src={'../../../../images/locks/rear-units/5g-bolt.png'}
                        loading={'lazy'}
                        width={364}
                        height={247}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Bolt rear unit"
                    />
                  </div>
                  <div>
                    <h2>
                      {t('deadlatch')}
                    </h2>
                    <h3>
                      {t('rear_unit')}
                    </h3>
                    <p>
                      {t('deadlatch_rear_unit_copy')}
                    </p>
                    <h3>
                      {t('assigned_use')}
                    </h3>
                    <p>
                      {t('deadlatch_assigned_use_copy')}
                    </p>
                  </div>
                </div>
                <div className="grid-two-col-item">
                  <div>
                    <StaticImage
                        src={'../../../../images/locks/rear-units/5g-latch.png'}
                        loading={'lazy'}
                        width={364}
                        height={247}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Latch rear unit"
                    />
                  </div>
                  <div>
                    <h2>
                      {t('deadbolt')}
                    </h2>
                    <h3>
                      {t('rear_unit')}
                    </h3>
                    <p>
                      {t('deadbolt_rear_unit_copy')}
                    </p>
                    <h3>
                      {t('shared_use')}
                    </h3>
                    <p>
                      {t('deadbolt_shared_use_copy')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="section-delimeter container" />
          <section className="management">
            <div className="container">
              <h1>{t('management')}</h1>
              <div className="grid-two-col">
                <div className="grid-two-col-item">
                  <div>
                    <StaticImage
                        src={'../../../../images/locks/management/app-manage-keys-all.png'}
                        loading={'lazy'}
                        width={169}
                        height={192}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Management keys"
                    />
                  </div>
                  <div>
                    <h2>
                      {t('key_management')}
                    </h2>
                    <p>
                      {t('key_management_copy')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
  );
};

export default Axis;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
